<template>
	<div class="monthIncomeSummary">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">记账日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="monthrange" range-separator="至" start-placeholder="开始月份"
							end-placeholder="结束月份" value-format="yyyy-MM" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<div ref="monthEcharts" style="width: 100%; height: 600px;"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				loading: true,
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/financial/fee/month/lst`, {
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						let xData = []
						let yData = []
						data.forEach(item => {
							xData.push(item.fee_type)
							yData.push(item.amount)
						});
						var monthEcharts = echarts.init(this.$refs.monthEcharts);
						var option = {
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								}
							},
							xAxis: [{
								type: 'category',
								data: xData
							}],
							yAxis: [{
								type: 'value'
							}],
							series: [{
								name: '月支出汇总',
								type: 'bar',
								stack: 'total',
								label: {
									show: true
								},
								data: yData
							}]
						};
						monthEcharts.setOption(option);
						monthEcharts.resize();
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				return `${year}-${month}`;
			}
		},
	}
</script>

<style lang="scss" scoped></style>